@import '../../vars.css';

.InviteOrCopyButtons {
  width: 86px;
  height: 39px;
}

.InviteOrCopyButtons_invite-button.Button {
  width: 100%;
  height: 22px;
  padding: 0;

  font-size: 12px;
}

.InviteOrCopyButtons_copy-url {
  margin-top: 3px;

  text-align: center;
  font-size: 10px;
  color: var(--color-neutral-400);
}

.InviteOrCopyButtons_copy-url a,
.InviteOrCopyButtons_copy-url a:hover {
  cursor: pointer;
  color: var(--color-neutral-400);
}

.InviteOrCopyButtons_copy-url.active,
.InviteOrCopyButtons_copy-url.active a,
.InviteOrCopyButtons_copy-url.active a:hover {
  color: inherit;
}

.InviteOrCopyButtons_copy-feedback.hide,
.InviteOrCopyButtons_invite-button.hide,
.InviteOrCopyButtons_copy-url.hide {
  display: none;
}

.InviteOrCopyButtons_copy-feedback {
  width: 100%;
  height: 100%;
  padding-top: 10px;

  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: var(--color-green-500);
  background-color: var(--color-green-100);
  border-radius: 3px;
}

.InviteOrCopyButtons_copy-feedback > i {
  position: relative;
  top: 2px;
  padding-right: 6px;

  font-size: 18px;
}
