@import '../../vars.css';

.Header .Container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  height: 60px;
  padding: 0 var(--header-padding-side);

  background-color: var(--color-dark-gray-bg);
  justify-content: space-between;
  align-items: center;
}

.Header_menu_left-block,
.Header_menu_right-block {
  display: flex;
  align-items: center;
  height: inherit;
  gap: 48px;
}

.Header_menu_left-block ul {
  padding-left: 0;
  gap: 30px;
}

.Header_menu_right-block ul {
  padding-left: 0;
  gap: 12px;
}

.Header_menu_right-block ul li {
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
}

.Header_logo {
  display: inherit;
}

.Header_logo,
.Header_logo:hover,
.Header_logo:focus {
  text-decoration: none;
}

.Header_logo-icon {
  width: 159px;
  height: 22px;
  background-image: url('licenseServerLogo.svg');
  background-repeat: no-repeat;
}

.icon-notification,
.icon-help,
.icon-chevron {
  font-size: 24px;
}
.icon-notification,
.icon-help {
  padding: 4px;
}

.Dropdown.pressed:focus .icon-chevron {
  transform: rotate(180deg);
}

.Header_Avatar {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: var(--color-neutral-50);
  font-family: Arial, sans-serif;
  font-size: 16px;
  background-color: var(--color-blue-600);
}

.redBg {
  background-color: var(--color-red-600);
}
.blueBg {
  background-color: var(--color-blue-600);
}
.greenBg {
  background-color: var(--color-green-600);
}
.orangeBg {
  background-color: var(--color-orange-600);
}

.Header ul {
  height: inherit;
  display: flex;
  align-items: center;
}

.Header li {
  float: left;
  list-style: none;
}

.Header li a {
  display: inline-block;

  padding-bottom: 18px;
  padding-top: 18px;
}

.Header li a,
.Header li a:hover,
.Header li a:focus {
  color: var(--color-neutral-200, #CACACA);
  background-color: transparent;
  text-decoration: none;
  border-bottom: 4px solid transparent;
}

.Header li a:hover,
.Header li a.active {
  border-color: var(--color-green-500, #95C11F);
}

.Header li a.active {
  color: white;
}

.Header_notification-count {
  position: absolute;
  padding: 0 4px;
  top: -6px;
  right: -6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--color-neutral-50, #F8F8F8);
  border-radius: 50%;
  color: var(--color-text);
  font-size: 9px;
  font-style: normal;
  font-weight: 590;
  line-height: 14px;
}

/*.Header_notification-count.new {
  background: var(--color-orange-600);
}*/

.headerIcon {
  position: relative;
  display: flex;
  border: none;
  padding: 0;
  color: var(--color-neutral-200, #CACACA);
  background-color: transparent;
  text-decoration: none;
}

.Header_menu_right-block ul li:hover {
  background: var(--color-neutral-700, #585858);
}

.menuItemWithSubtext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.menuItemWithSubtext h6 {
  overflow: hidden;
  color: var(--color-neutral-400);
  text-overflow: ellipsis;
  font-weight: normal;
}
