@import '../../../vars.css';

.TeamForm_modal .Modal_header {
  padding-bottom: 17px;
}

.TeamForm .Modal_footer:has(.TeamForm_delete-team-button) {
  flex-direction: row;
  justify-content: space-between;
}

.TeamForm .Modal_footer a {
  cursor: pointer;
  padding-top: 8px;

  color: inherit;
}

.TeamForm_delete-team-button.Button.link,
.TeamForm_delete-team-button.Button.link:focus,
.TeamForm_delete-team-button.Button.link:hover {
  text-decoration: underline;
  display: inline;
  padding: 0;
  color: var(--color-text);
}

.TeamForm_team-lead-disabled-checkbox {
  margin-top: -7px;
}

.TeamForm_team-lead-disabled-checkbox label {
  font-weight: normal;
}

.TeamForm_team-lead-disabled-checkbox input[type='checkbox'] {
  margin-left: 0;
  margin-right: 8px;
}
