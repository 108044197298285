.SsoLoginPage_box {
    width: 370px;
    margin: 0 auto;
}

.SsoLoginPage_button {
    width: 100%;
}

.SsoLoginPage_spacer {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #B3B3B3;
    border-bottom: 1px solid #B3B3B3;
    line-height: 0.1em;
    margin: 20px 0 20px;
}

div.SsoLoginPage_spacer span {
    background:#fff;
    padding:0 10px;
}
