@import '../../vars.css';

.Modal_close-button {
  position: relative;
  z-index: 1;
  padding: 0;

  outline: none;
  background: none;
  border: none;

  width: 24px;
  height: 24px;
  font-size: 20px;
  color: var(--color-neutral-900);
}

.Modal .Modal_show {
  display: block!important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
  overflow: hidden;
}

.Modal .Modal_backdrop {
  opacity: var(--opacity-overlay);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: black;
  transition: opacity .15s linear;
}

.Modal .Modal_dialog {
  display: flex;
  width: 752px; /* Default to large */
  flex-direction: column;
  margin: 30px auto;
  position: relative;
}

.Modal_dialog.l {
  width: 752px;
}

.Modal_dialog.m {
  width: 600px;
}

.Modal_dialog.s {
  width: 400px;
}

.Modal .Modal_content {
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  position: relative;
  background-color: white;
  background-clip: padding-box;
  border-radius: 6px;
  outline: 0;
}

.Modal .Modal_header {
  padding: 40px var(--modal-padding-side) 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: none;
}

.Modal .Modal_header h2 {
  margin-bottom: 0;

  font-weight: bold;
  color: var(--color-green-500);
}

.Modal .Modal_body {
  padding: 0 var(--modal-padding-side);
}

.Modal .Modal_footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;

  overflow: hidden;
  padding: 30px var(--modal-padding-side) 50px;

  border: none;
  text-align: right;
}

/* Add room between buttons if there is cancel button TODO should we change how we 'import' buttons from components to have less hacky styling?
 https://stackoverflow.com/questions/18738052/how-to-add-css-if-element-has-more-than-one-child */
.Modal_footer div:has(.ModalButton.secondary) {
  display: flex;
  gap: 8px;
}

.Modal .InputField_label,
.Modal .SelectField_label {
  font-weight: normal;
}

.Modal .InputField_input,
.Modal .SelectField_select {
  padding: 6px 8px;
  height: var(--modal-height-input);
}

.Modal .InputField_error-icon {
  line-height: var(--modal-height-input);
}

.ModalConfirmDanger_modal.Modal_dialog{
  margin-top: 120px;
}
