@import '../../../vars.css';


.TeamRedeployTimeModal_modal.Modal_dialog {
  width: 1200px;
}

.TeamRedeployTimeModal_modal .Modal_header {
  padding-bottom: 17px;
}

.TeamRedeployTimeModal_modal_description {
  padding-bottom: 30px;
  font-size: 18px;
}

.TeamRedeployTimeModal_table-panel {
  max-height: 50vh;
  overflow-y: auto;
}

.TableRow.TeamRedeployTimeModal_no-results {
  text-align: center;
}

.TeamRedeployTimeModal_table.Table > tbody > .TableRow .TableCell {
  padding-top: 11px;
  padding-bottom: 11px;
}

.TeamRedeployTimeModal_table_team-name {
  width: 317px;
}

.TableCell.TeamRedeployTimeModal_table_team-name {
  font-size: 14px;
  font-weight: bold;
}

.TableCell.TeamRedeployTimeModal_table_team-lead {
  font-size: 10px;
  color: var(--color-neutral-400);
}

.TeamRedeployTimeModal_table_team-lead,
.TeamRedeployTimeModal_table_users,
.TeamRedeployTimeModal_table_redeploy-time {
  width: 175px;
}

.Table > tbody > .TableRow .TableCell.TeamRedeployTimeModal_table_team-lead,
.Table > thead > .TableRow > th.TeamRedeployTimeModal_table_team-lead {
  text-align: right;
  padding-right: 20px;
}

.TeamRedeployTimeModal_table_redeploy-time .InputField {
  margin: 0;
  width: auto;
  display: inline-block;
}

.TableCell.TeamRedeployTimeModal_table_redeploy-time input {
  width: 61px;
  margin: 0 5px;
  padding: 0 8px;
}
