
.DataRecapTable {
  width: 610px;
}

.DataRecapTable_item {
  display: inline-flex;
  width: 50%;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--font-weight-semi-bold);
  /* color: #7A7A9D; */
}

.DataRecapTable_item_icon.icon {
  padding-right: 42px;
  padding-left: 10px;
  font-size: 20px;
  width: 14px;
}

.DataRecapTable_item_label {
}

.DataRecapTable_item_value {
  margin-left: auto;
  margin-right: 19px;
}
