@import '../../../../vars.css';

:root {
  --table-cell-padding: 8px;
  --user-column-width: 450px;
}

.TableRow.UsersTable_no-results {
  align-items: center;
  text-align: center;
}

.TableRow.UsersTable_no-results > .TableCell {
  padding: 16px 0 !important;
}

.UsersTable_no-results_message {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 56px;
  justify-content: center;
  padding: 32px 0;
  text-align: start;
}

.UsersTable_no-results_message_text {
  display: inherit;
  flex-direction: column;
  gap: 8px;
}

.UsersTable_no-results_message h2 {
  color: var(--color-green-500);
  font-weight: bold;
}

.UsersTable_no-results_message_image {
  background-image: url('../empty-folder.svg');
  background-repeat: no-repeat;
  height: 240px;
  width: 240px;
}

.UsersTable_domain-warning {
  margin-right: 5px;
}

th.UsersTable_column {
  width: 350px;
}

th.UsersTable_date-column {
  width: 350px;
}

th.UsersTable_user-column {
  min-width: var(--user-column-width);
}

th.UsersTable_team-column {
  width: 350px;
}

th.UsersTable_product-column {
  width: 350px;
}

th.UsersTable_blocked-column {
  width: 350px;
}

.UsersTable_offline-label {
  color: var(--color-neutral-400);
  font-size: 10px;
  margin-left: 5px;
}

th.UsersTable_last-active-column {
  width: 350px;
}

.UsersTable.Table > thead > .TableRow > th:last-child,
.UsersTable.Table > tbody > .TableRow .TableCell:last-child {
  padding-left: 0;
}

.UsersTable_user-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(var(--user-column-width) - var(--table-row-padding-side) - var(--table-cell-padding));
}

.UsersTable_blocked-by-label {
  color: var(--color-neutral-400);
  font-size: 10px;
}

th.UsersTable_actions-column {
  width: 60px;
}

.AddUsersModal p {
  margin-bottom: 24px;
}

.AddUsersModal_email {
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
}

.AddUsersModal_email_input {
  flex: 1;
}

.AddUsersModal_email_add-button {
  height: var(--modal-height-input);
  margin-top: 24px;
}

.AddUsersModal_bubbles {
  margin-bottom: 8px;
  margin-top: -12px;
}

.AddUsersModal_team {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.AddUsersModal_team_select {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.AddUsersModal_team_text {
  color: var(--color-neutral-500);
  margin-bottom: 0 !important;
}

.AddUsersModal_new-team {
  position: absolute;
  left: 50px;
  bottom: 58px;
}

th.UsersTable_checkbox-column {
  width: 48px;
}
