@import 'clsicons/styles.css';

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url('OpenSans.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  src: url('OpenSansBold.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('OpenSansSemibold.woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('OpenSansLight.woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: normal;
  src: url('RobotoMono.woff');
}
