@import '../../../vars.css';

/* Bulk Action panel */

.BulkAction {
  align-items: center;
  background-color: white;
  border-radius: 6px;
  bottom: 84px;
  box-shadow: 0 18px 60px rgba(0, 0, 0, 0.06), 0 9px 26px rgba(0, 0, 0, 0.04), 0 4px 10px rgba(0, 0, 0, 0.03), 0 1px 4px rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-start;
  left: calc(50% - 320px);
  padding: 8px 24px 8px 8px;
  position: fixed;
  text-align: left;
}

.BulkAction_selected {
  align-items: center;
  border-right: 1px solid var(--color-neutral-200);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 8px 16px;
  width: 160px;
}

.BulkAction_items {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.BulkAction_item {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.BulkAction_cancel {
  align-items: flex-start;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 8px;
}

.BulkAction_cancel:hover {
  background-color: var(--color-neutral-50);
}

.BulkAction_cancel-icon {
  flex-shrink: 0;
  height: 16px;
  overflow: hidden;
  position: relative;
  width: 16px;
}

.BulkAction_cancel-icon:hover {
  background-color: var(--color-neutral-50);
}

/* Bulk Action item */

.BulkActionItem {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 6px 24px;
}

.BulkActionItem:hover {
  background-color: var(--color-neutral-50);
}

.BulkActionItem.disabled {
  color: var(--color-neutral-100);
  cursor: not-allowed;
}

/* Bulk Action checkbox */

.BulkAction_checkbox-row input {
  display: none;
}

.TableRow:hover .BulkAction_checkbox-row input,
.BulkAction_checkbox-row input[type="checkbox"]:checked {
  display: inline;
}
