@import '../../vars.css';

.SelectField {
  margin-bottom: 15px;
}

.SelectField_select {
  border: 1px solid var(--color-neutral-200, #CACACA);
  border-radius: 4px;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background-color: white;
  font-family: inherit;
}

.SelectField_select::placeholder {
  color: var(--color-placeholer);
  opacity: 0.5;
}

.SelectField_select:-ms-input-placeholder {
  color: var(--color-placeholer);
}

.SelectField_select::-ms-input-placeholder {
  color: var(--color-placeholer);
}

.SelectField_select:disabled {
  cursor: not-allowed;
  background-color: var(--color-neutral-100);
  opacity: 1;
}

.SelectField_select:focus {
  border: 1px solid var(--color-green-500);
  border-color: var(--color-green-500);
  outline: none;
}

.SelectField_label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.SelectField_input-wrapper {
  position: relative;
}
