.SsoDetails_details-title {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.SsoDetails_details-value {
    flex: 1;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.SsoDetails_details-value-container {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
}
.SsoDetails_details-row {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 12px;
    gap: 8px;
}

.SsoDetails_details {
    width: 100%;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    text-align: left;
    font-size: 16px;
}

.SsoDetails_modal {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 36px;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
}

.SsoDetails_value-tooltip {
    background: transparent;
    border: 0;
}

.SsoDetails_details-value .CopyTextButton_tooltip {
    position: fixed;
}
