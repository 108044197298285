:root {

  --color-neutral-50: #F8F8F8;
  --color-neutral-100: #E0E0E0;
  --color-neutral-200: #CACACA;
  --color-neutral-300: #B3B3B3;
  --color-neutral-400: #9C9C9C;
  --color-neutral-500: #808080;
  --color-neutral-600: #6A6A6A;
  --color-neutral-700: #585858;
  --color-neutral-800: #494949;
  --color-neutral-900: #333333;

  --color-green-50: #FAFEF1;
  --color-green-100: #F1FBD6;
  --color-green-200: #E0F2B0;
  --color-green-300: #C4E278;
  --color-green-400: #A6D42B;
  --color-green-500: #95C11F;
  --color-green-600: #86A82C;
  --color-green-700: #759819;
  --color-green-800: #5E7B14;
  --color-green-900: #4B6110;

  --color-red-50: #FFF0EB;
  --color-red-100: #FFD5CC;
  --color-red-200: #FFB7AD;
  --color-red-300: #FF8480;
  --color-red-400: #F75F5F;
  --color-red-500: #E94C49;
  --color-red-600: #D43F3A;
  --color-red-700: #C9302C;
  --color-red-800: #AC2925;
  --color-red-900: #882825;

  --color-blue-50: #EFF5FA;
  --color-blue-100: #DFECF6;
  --color-blue-200: #CBE0F0;
  --color-blue-300: #ADCFEB;
  --color-blue-400: #9DC3E1;
  --color-blue-500: #81B2D9;
  --color-blue-600: #629FD0;
  --color-blue-700: #468BC1;
  --color-blue-800: #3274A9;
  --color-blue-900: #2A618D;

  --color-orange-50: #FFF8EB;
  --color-orange-100: #FFEFD1;
  --color-orange-200: #FFE5A3;
  --color-orange-300: #FFD68A;
  --color-orange-400: #FEC762;
  --color-orange-500: #FFBB33;
  --color-orange-600: #FEA501;
  --color-orange-700: #F49506;
  --color-orange-800: #E58801;
  --color-orange-900: #CB7901;

  --color-dark-gray-bg: #363B3F; /* Header */

  --font-weight-extra-bold: 800;
  /* use 'bold' for weight 700*/
  --font-weight-semi-bold: 600;
  /* use 'normal' for weight 400/'regular'*/

  --font-family-code: Roboto Mono, serif;
  --font-family-base: 'Open Sans', Arial, Helvetica, sans-serif;

  --opacity-overlay: 0.4;

  --width-content: 1244px;
  --height-footer: 177px;
  --page-padding-side: 36px;
  --page-padding-bottom: 50px;
  --modal-padding-side: 48px;
  --modal-height-input: 32px;
  --table-row-padding-side: 16px;
  --action-button-size: 22px;

  @media (min-width: 1441px) {
    --header-padding-side: 160px;
    --page-margin-side: 160px; /* TODO: fix anomalies on pages caused by this change ie Analytics */
  }

  @media (max-width: 1440px) and (min-width: 1134px) {
    --header-padding-side: 48px;
    --page-margin-side: 48px;
  }

  @media (max-width: 1133px) {
    --header-padding-side: 32px;
    --page-margin-side: 48px;
  }
}
