.LoginPage_box {
  width: 370px;
  margin: 0 auto;
}

.LoginPage_box .Form {
  width: 100%;
}

.LoginPage_forgot-password-link {
  margin-top: 7px;
}
