@import '../../../vars.css';

:root {
  @media (min-width: 1597px) {
    --user-page-filter-width: 246px;
  }

  @media (max-width: 1596px) and (min-width: 1175px) {
    --user-page-filter-width: 196px;
  }

  @media (max-width: 1174px) {
    --user-page-filter-width: 146px;
  }
}

.UsersPage_filters_actions {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.UsersPage_filters_actions button {
  white-space: nowrap;
}

.UsersPage_filters_actions_search {
  width: var(--user-page-filter-width);
}

.UsersPage_filters {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 48px;
  justify-content: flex-start;
}

.UsersPage_filters_selects {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
  justify-content: flex-start;
}

.UsersPage_details {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
}

.UsersPage_filters_selects_select {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: var(--user-page-filter-width);
}
