
.EnableDomainWhitelistingModal_seat-users-list {
  padding: 8px;
  display: inherit;
  flex-direction: inherit;
  align-self: inherit;
}

.EnableDomainWhitelistingModal_seat-users-list.pre-scrollable {
  max-height: 140px;
}

.EnableDomainWhitelistingModal_seat-users-list h6 {
  color: var(--color-neutral-400);
  font-weight: var(--font-weight-semi-bold);
}

.Modal_body:has(> .EnableDomainWhitelistingModal_confirmation-text) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
}

.Modal_body p {
  margin: 0;
}
