@import '../../vars.css';

.SearchField {
  align-items: flex-start;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.SearchField_input {
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--color-neutral-200, #CACACA);
  border-radius: 4px;
  display: flex;
  font-family: inherit;
  gap: 8px;
  height: 34px;
  padding-left: 32px;
  width: 100%;
}

.SearchField_input::placeholder {
  color: var(--color-placeholer);
  opacity: 0.5;
}

.SearchField_input::-ms-input-placeholder {
  color: var(--color-placeholer);
}

.SearchField_input:disabled {
  background-color: var(--color-neutral-100);
  cursor: not-allowed;
  opacity: 1;
}

.SearchField_input:focus {
  border: 1px solid var(--color-green-500);
  border-color: var(--color-green-500);
  outline: none;
}

.SearchField_label {
  display: inline-block;
  font-weight: bold;
  max-width: 100%;
}

.SearchField_wrapper {
  position: relative;
  width: 100%;
}

.SearchField_wrapper i.icon {
  color: var(--color-neutral-200) !important;
  font-size: 16px;
  left: 8px;
  pointer-events: none;
  position: absolute;
  top: 10px;
}
