.PasswordResetPage {
  width: 370px;
  margin: 0 auto;
}

.PasswordResetPage_privacy-link {
  margin-bottom: 10px;
}

.PasswordResetPage_agree-with-terms-label {
  font-weight: normal;
}

.PasswordResetPage_submit-button {
  margin-top: 12px;
}
