@import '../../../vars.css';

.NotificationPage_table .TableRow:hover {
  background-color: var(--color-neutral-50);
}

.NotificationPage_table_name-column {
  width: 250px;
}

.NotificationPage_table_description-column {
  min-width: 580px;
}

.NotificationPage_table_date-column {
  width: 95px;
}

.NotificationPage_table_action-column {
  width: 176px;
}

.NotificationPage_table_archive-column {
  width: 72px;
}

.NotificationPage_no-results {
  text-align: center;
  align-items: center;
}

.TableRow.NotificationPage_no-results > .TableCell {
  padding: 16px 0 !important;
}

.NotificationPage_no-results-message {
  display: flex;
  padding: 32px 0;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  text-align: start;
  gap: 56px;
}

.NotificationPage_no-results-text {
  display: inherit;
  flex-direction: column;
  gap: 8px;
}

.NotificationPage_no-results-message h2 {
  color: var(--color-green-500);
  font-weight: bold;
}

.NotificationPage_no-results-image {
  width: 240px;
  height: 240px;
  background-image: url('no-notifications.svg');
  background-repeat: no-repeat;
}


.NotificationPage_table_name {
  font-weight: bold;
}

.NotificationPage_table_name.new:before{
  background-color: var(--color-orange-600);
  border-radius: 2px;
  content: '';
  height: 4px;
  margin-left: -8px;
  margin-top: 8px;
  position: absolute;
  width: 4px;
}

.NotificationPage_table_cell,
.NotificationPage_table_archive-button {
  color: var(--color-neutral-400);
  font-size: 10px;
}

.NotificationPage_table_action-button.Button {
  padding: 0 10px 2px;
  font-size: 12px;
  height: 20px;
  text-decoration: none;
  width: 100%;
}

.Add_to_whitelist-button.Button {
  padding: 0 4px 2px;
}

.NotificationPage_table_archive-button {
  background: transparent;
  border: none;
  text-decoration: underline;
}
