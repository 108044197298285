@import '../../../vars.css';

.ExpandableAlert .Alert_icon {
  cursor: pointer;
}

.ExpandableAlert_title {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.ExpandableAlert_content {
  margin-top: 10px;
}
