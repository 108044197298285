.RoiCalculatorTab {
  overflow: hidden;
  background-color: var(--color-neutral-50); /* TODO do we want it blue-50 or..?*/
}

.RoiCalculatorTab > div {
  background-color: white;
}

.RoiCalculatorTab label {
  font-weight: normal;
}

.RoiCalculatorTab input,
.RoiCalculatorTab select {
  text-align: center;
}

.RoiCalculatorTab h5 {
  font-size: 18px;
  font-weight: bold;
}

.RoiCalculatorTab_salary-panel {
  height: 100px;
  width: 100%;
  padding:24px;
}

.RoiCalculatorTab_salary-row {
  padding-bottom: 25px;
}

.RoiCalculatorTab_salary-row_form {
   float: right;
 }

.RoiCalculatorTab_salary-row_form .SelectField .SelectField_select {
  /*TODO: Upgrade to the new style inputs which are narrower*/
  width: 75px;
  margin-left: 0;
}

.RoiCalculatorTab_salary-row_form .InputField {
  width: auto;
}

.RoiCalculatorTab_salary-row_form .InputField .InputField_input {
  /*TODO: Upgrade to the new style inputs which are narrower*/
  width: 80px;
  /*padding-left: 12px;*/
  /*padding-right: 6px;*/
}

.RoiCalculatorTab_salary-row_form div {
  display: inline-block;
}

.RoiCalculatorTab_salary-row input,
.RoiCalculatorTab_salary-row select {
  margin-left: 10px;
  width: 100px;
}

.RoiCalculatorTab_panel-first_row_title {
  padding-top: 5px;
  display: inline-block;
  width: 60%;
}

.RoiCalculatorTab_column {
  margin-top: 15px;
  margin-bottom: 15px;
}

.RoiCalculatorTab_column.top-row {
  margin-top: 0;
}

.RoiCalculatorTab_column.bottom-row {
  margin-bottom: 0;
}

.RoiCalculatorTab_column.left-column {
  margin-right: 15px;
  width: 642px;
  float: left;
}

.RoiCalculatorTab_column.right-column {
  padding: 15px;
  width: 100%;
}

.RoiCalculatorTab_column.full-width-column {
  width: 100%;
  float: left;
}

.RoiCalculatorTab_column.full-width-column.last-row {
  margin-bottom: 0;
}

.RoiCalculatorTab_column.full-width-column.container-card {
  padding: 12px 15px; /* 12, 16 */
}

/* Calculated ROI Panel */

.CompanySavingsInputLabel{
  padding-left: 20px;
}

.CompanySavingsInputLabel_primary {
  font-weight: var(--font-weight-semi-bold);
  font-size: 14px;
}

.CompanySavingsInputLabel_secondary {
  font-size: 11px;
}

.RoiCalculatorTab_panel_inputs > * {
  display: inline-block;
}

.RoiCalculatorTab_panel_inputs {
  width: 340px;
}

.RoiCalculatorTab_panel_inputs_heading {
  display: inline-block;
  width: 100%;
  margin-bottom: 28px;
  margin-left: 10px;
}

.RoiCalculatorTab_panel_inputs .RoiCalculatorTab_input {
  display: flex;

  width: 100%;
  margin-top: 15px;
  margin-bottom: 21px;
}

.RoiCalculatorTab_panel_inputs .RoiCalculatorTab_input .RoiCalculatorTab_panel_inputs_input_label {
  display: inline-block;
  width: 270px;
}

.RoiCalculatorTab_panel_inputs .RoiCalculatorTab_input .InputField {
  display: inline-block;
  width: 70px;
  margin-bottom: 0;
}

.RoiCalculatorTab_panel_inputs .RoiCalculatorTab_panel_inputs input {
  margin-top: 7px;
  width: 75px;
}

.ReloadTimeConfigurePanel {
  width: 100%;
  height: 100px;
  padding: 24px 32px;
}

.RoiSummaryWrapper {
  width: 364px;
}

.RoiCalculatorTab_CallToAction_panel {
  height: 332px;
}

.RoiCalculatorTab_CallToAction {
  padding: 33px 20px 20px;
}

.RoiCalculatorTab_CallToAction_side {
  display: inline-block;
  width: 50%;
}

.RoiCalculatorTab_CallToAction_side-left {
  padding-left: 40px;

}

.RoiCalculatorTab_CallToAction_side-right {
  padding-left: 135px;
  border-left: 1px solid var(--color-neutral-500);
}

.RoiCalculatorTab_CallToAction_side_heading{
  padding-bottom: 10px;
}

.RoiCalculatorTab_CallToAction_side_body {
  font-size: 16px;
  line-height: 28px;
  padding-top: 5px;
  padding-bottom: 20px;
}

.RoiCalculatorTab_CallToAction_side_link > a {
  background-color: var(--color-green-50);
  display: inline-block;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: -6px;
}

.RestoreDefaults {
  margin-right: 10px;
}

.CompanySavingsPanel {
  height: 360px;
}
