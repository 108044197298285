@import '../../vars.css';

:root {

}

.UnsubscribePage_box {
  height: 270px;
  padding-top: 90px;
  padding-left: var(--page-padding-side);
  padding-right: var(--page-padding-side);
  margin: 144px calc(-1 * var(--page-padding-side));
  text-align: center;
  background-color: var(--color-neutral-50);
}

.UnsubscribePage_message h1 {
  color: var(--color-green-500);
}

.UnsubscribePage_help-text {
  margin-top: 20px;
  font-size: 18px;
}
