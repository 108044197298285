@import '../../vars.css';

.NewSelectField_container {
}

.NewSelectField__control {
  min-height: 34px !important;
}

.NewSelectField__control--is-focused,
.NewSelectField__control--menu-is-open {
  border: 1px solid var(--color-green-500) !important;
  box-shadow: none !important;
  outline: none !important;
}

.NewSelectField__indicator .icon {
  color: var(--color-green-200) !important;
  font-size: 16px;
}

.NewSelectField__menu {
  border: 1px solid var(--color-neutral-100) !important;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06), 2px 8px 14px 0 rgba(0, 0, 0, 0.08) !important;
}

.NewSelectField__menu-list::-webkit-scrollbar {
  width: 8px !important;
}

.NewSelectField__menu-list::-webkit-scrollbar-thumb {
  background: var(--color-neutral-300);
  border-radius: 100px;
}

.NewSelectField__menu-list::-webkit-scrollbar-track {
  background-color: var(--color-neutral-50) !important;
  border-radius: 100px !important;
}

.NewSelectField__option,
.NewSelectField__option--is-focused,
.NewSelectField__option--is-selected {
  background-color: #fff !important;
}

.NewSelectField__option--is-selected {
  color: var(--color-green-500) !important;
}

.NewSelectField__placeholder {
  color: var(--color-neutral-200) !important;
}
