@import '../../../../vars.css';

:root {
  --user-column-width: 450px;
}

.TableRow.AdminsTable_no-results {
  align-items: center;
  text-align: center;
}

.TableRow.AdminsTable_no-results > .TableCell {
  padding: 16px 0 !important;
}

.AdminsTable_no-results_message {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 56px;
  justify-content: center;
  padding: 32px 0;
  text-align: start;
}

.AdminsTable_no-results_message_text {
  display: inherit;
  flex-direction: column;
  gap: 8px;
}

.AdminsTable_no-results_message h2 {
  color: var(--color-green-500);
  font-weight: bold;
}

.AdminsTable_no-results_message_image {
  background-image: url('../empty-folder.svg');
  background-repeat: no-repeat;
  height: 240px;
  width: 240px;
}

th.AdminsTable_column {
  width: 350px;
}

th.AdminsTable_email-column {
  min-width: var(--user-column-width);
}

th.AdminsTable_actions-column {
  width: 60px;
}

th.AdminsTable_checkbox-column {
  width: 48px;
}
