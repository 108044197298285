@import '../../vars.css';

.Page_alert .Alert{
  margin-bottom: 24px;
}

.Page_heading-row {
  width: 100%;
}

.Page_heading-row > * {
  display: inline-block;
}

.Page_heading {
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: 18px;
}

.Page_heading-widget {
  position: relative;
  bottom: 5px;
  margin-left: 20px;
}

.Page_buttons {
  position: relative;
  bottom: 6px;
  margin-left: 12px;
}
