@import '../../../../../../vars.css';

.TableRow.SsoList_fetching {
  text-align: center;
}

.SsoList_toggle-all {
  margin: 0 10px 0 10px;
}

.SsoList_type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  gap: 10px;
}

.SsoList_status-label {
  padding: 2px 6px;
  background: var(--color-red-50);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex
}

.SsoList_status-label-text {
  color: var(--color-red-800);
  font-size: 12px;
  font-weight: bold;
  word-wrap: break-word
}

.SSoList_created-label {
  color: var(--color-neutral-400);
  font-size: 12px;
  word-wrap: break-word
}

.SsoList_no-results {
  align-items: center;
}

.TableRow.SsoList_no-results > .TableCell {
  padding: 16px 0 !important;
}

.SsoList_no-results-message {
  display: flex;
  padding: 32px 0;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  text-align: start;
  gap: 56px;
}

.SsoList_no-results-text {
  display: inherit;
  flex-direction: column;
  gap: 8px;
}

.SsoList_no-results-message h2 {
  color: var(--color-green-500);
  font-weight: bold;
}


.SsoList_no-results-image {
  background-image: url('no-sso-configs.svg');
  background-repeat: no-repeat;
  height: 240px;
  width: 240px;
}

.SSoList_actions-column {
  text-align: right;
}

.Table > tbody > .TableRow > .TableCell.SSoList_actions-column2 {
  padding: 0;
}
