@import '../../../vars.css';

/* TODO Temporary fix to make Analytics page static, to be addressed in CLS-816 */
/* TODO 'has' not supported in IE 11, does that matter? */
.App_container.Container:has(> .AnalyticsPage) {
  width: calc(var(--width-content) - var(--page-padding-side) * 2);
  margin: auto;
}

.App_container.Container:has(> .AnalyticsPage) .Card {
  display: block;
}
