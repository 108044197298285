@import '../../vars.css';

.Popup {
  padding: 10px;
  pointer-events: none;

  background-color: var(--color-neutral-900);
  border-radius: 4px;
}

.Popup.xs {
  width: 88px;
}

.Popup.s {
  width: 174px;
}

.Popup.m {
  width: 194px;
}

.Popup.l {
  width: 231px;
}

.Popup.arrow-at-top:before {
  position: absolute;
  top: 0;
  left: 50%;
  content: ' ';

  margin-top: -18px;
  margin-left: -9px;
  border: 9px solid transparent;
  border-bottom-color: var(--color-neutral-900);
}

.Popup.error.arrow-at-top:before {
  border-bottom-color: var(--color-red-600);
}

.Popup.error.arrow-at-top:after {
  position: absolute;
  top: 0;
  left: 50%;
  content: ' ';

  margin-top: -17px;
  margin-left: -9px;
  border: 9px solid transparent;
  border-bottom-color: var(--color-red-50);
}

.Popup.arrow-at-bottom:before {
  position: absolute;
  top: 100%;
  left: 50%;
  content: ' ';

  margin-left: -9px;
  border: 9px solid transparent;
  border-top-color: var(--color-neutral-900);
}

.Popup.error.arrow-at-bottom:before {
  border-top-color: var(--color-red-600);
}

.Popup.error.arrow-at-bottom:after {
  position: absolute;
  top: 100%;
  left: 50%;
  content: ' ';

  margin-top: -1px;
  margin-left: -9px;
  border: 9px solid transparent;
  border-top-color: var(--color-red-50);
}

.Popup_text {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: white;
}

.Popup.error {
  border: 1px solid var(--color-red-600);
  background-color: var(--color-red-50);
}

.Popup.error .Popup_text {
  color: var(--color-red-600);
}

.Popup.success.arrow-at-top:before {
  border-bottom-color: var(--color-green-100);
}

.Popup.error.arrow-at-top:before {
  border-top-color: var(--color-green-100);
}

.Popup.success {
  background-color: var(--color-green-100);
}

.Popup.success .Popup_text {
  color: var(--color-green-500);
  opacity: 1;
}
