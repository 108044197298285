@import '../../../../../vars.css';

.ActualRoiPanel_configuration-icon {
  margin-right: 5px;
}

.ActualRoiPanel_configuration-button.Button.link {
  color: var(--color-green-500);
}

.ActualRoiPanel_configuration-button.Button.link:focus,
.ActualRoiPanel_configuration-button.Button.link:hover {
  color: var(--color-green-500);
}

.ActualRoiPanel .RoiCalculatorPanel_header-left-part {
  width: 40%;
}

.ActualRoiPanel_period-select {
  display: inline-block;
  margin-top: 0;
  margin-left: 10px;
  margin-right: 10px;
  width: 150px;
}

.ActualRoiPanel_savings-in-period-text {
  margin-top: -2px;

  font-size: 18px;
  line-height: 1.6;
}

i.ActualRoiPanel_bad-roi-reason_icon {
  display: inline-block;
  position: relative;
  bottom: 2px;
  margin-right: 16px;

  font-size: 42px;
  color: var(--color-orange-600);
}

.ActualRoiPanel_bad-roi-reason_text {
  display: inline-block;
  width: 80%;
  margin-top: 34px;
  margin-bottom: 20px;

  font-size: 18px;
}

.ActualRoiPanel{
  height: 600px;
}

.ActualRoiPanel_recap {
  margin-top: 23px;
  font-weight: var(--font-weight-semi-bold);
  font-size: 14px;
}

.ActualRoiPanel_recap_heading {
  margin-bottom: 10px;
}

.ChartUnitsLabel {
  display: inline-block;
  margin-left: 22px;
}

.ChartUnitsLabel_dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--color-green-700);
  border-radius: 4px;
}

.ChartUnitsLabel_text {
  display: inline-block;
  margin-left: 10px;
}

