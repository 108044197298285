@import '../../vars.css';

.Table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
}

.TableRow {
  border-bottom: 1px solid var(--color-neutral-100);
}

.Table > tbody > .TableRow:last-child,
.Table > tfoot > .TableRow:last-child {
  border-bottom: 0;
}

.Table > thead > .TableRow > th {
  padding: 12px 16px;
  text-align: left;
}

.Table > thead > .TableRow:first-child > th:first-child {
  border-top-left-radius: 6px;
}

.Table > thead > .TableRow:first-child > th:last-child {
  border-top-right-radius: 6px;
}

.Table > tfoot > .TableRow:last-child > .TableCell:first-child {
  border-bottom-left-radius: 6px;
}

.Table > tfoot > .TableRow:last-child > .TableCell:last-child {
  border-bottom-right-radius: 6px;
}

.Table > thead > .TableRow > th select {
  padding: 0 4px;
  height: 22px;
  font-size: 12px;
}

.Table > tbody > .TableRow > .TableCell {
  height: 72px;
  padding: 16px;
}

.Table > tfoot > .TableRow > .TableCell {
  border-top: 1px solid var(--color-neutral-100);
  padding: 8px;
}

.Table > thead > .TableRow > th,
.Table > tbody > .TableRow > .TableCell,
.Table > tfoot > .TableRow > .TableCell {
  vertical-align: middle;
}

.Table > thead > .TableRow > th,
.Table > tfoot > .TableRow > .TableCell {
  font-weight: normal;
  background: var(--color-neutral-50);
  border-bottom: 0;
}

.Table > thead > .TableRow > th:first-child,
.Table > tbody > .TableRow > .TableCell:first-child,
.Table > tfoot > .TableRow > .TableCell:first-child {
  padding-left: var(--table-row-padding-side);
}

.Table > thead > .TableRow > th:last-child,
.Table > tbody > .TableRow > .TableCell:last-child,
.Table > tfoot > .TableRow > .TableCell:last-child {
  padding-right: var(--table-row-padding-side);
  border-right: 0;
}
