@import '../../../vars.css';

.Card.BillingPage_order-panel {
  margin-bottom: 25px;
}

.Table > thead > .TableRow > th.BillingPage_order-number {
  width: 100%;
  font-weight: bold;
}

.BillingPage_product-column {
  width: 320px;
}

.BillingPage_total-seats-column {
  min-width: 230px;
}

.BillingPage_start-date-column {
  min-width: 230px;
}

.BillingPage_end-date-column {
  min-width: 230px;
}

.BillingPage_export-column {
  width: 230px;
}

.BillingPage_actions-column {
  width: 60px;
}

.TableRow.BillingPage_row.de-emphasized {
  color: var(--color-neutral-400);
}

.BillingPage_expires-in-label {
  font-size: 10px;
  color: var(--color-neutral-400);
}

.BillingPage_export-managed {
  margin-right: 20px;
}

.BillingPage_export-dedicated {
  width: 32px;
}

.BillingPage_export-managed,
.BillingPage_export-dedicated {
  display: inline-block;
  float: right;
}

.BillingPage_export-managed-button {
  height: var(--action-button-size);
  display: flex;
  align-items: center;
  padding: 14px 16px;
  font-size: 12px;
  white-space: nowrap;
}

.no-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
