@import '../../../../vars.css';

.TeamFormProductsPanel {
  width: 100%;
  margin-top: 30px;
}

.TeamFormProductsPanel > thead > .TableRow > th {
  font-weight: bold;
  padding: 10px 0 20px 0;
  background: transparent;
}

.TeamFormProductsPanel > thead > .TableRow > th:first-child,
.TeamFormProductsPanel > tbody > .TableRow .TableCell:first-child {
  padding-left: 0;
}

.TeamFormProductsPanel > thead > .TableRow > th:last-child,
.TeamFormProductsPanel > tbody > .TableRow .TableCell:last-child {
  padding-right: 0;
}

.TeamFormProductsPanel > tbody > .TableRow.TeamFormProductsPanel_product .TableCell {
  padding: 10px 0;
}

.TeamFormProductsPanel_product:first-child .TableCell {
  border-top: 1px solid var(--color-neutral-100);
}

.TeamFormProductsPanel_product:last-child .TableCell {
  padding-bottom: 0;

  border-bottom: none;
}

.TeamFormProductsPanel_product_name {
  width: 150px;
}

.TeamFormProductsPanel_product_enabled {
  width: 80px;
}

.TeamFormProductsPanel_product_enabled label {
  height: 100%;
  padding: 10px 18px 8px 18px;
  margin: 0;
}

.TeamFormProductsPanel_product_enabled input[type="checkbox"] {
  margin: 0;
}

.TeamFormProductsPanel_product_seat-limit {
  width: 120px;
}

.TeamFormProductsPanel_product_seat-limit .InputField,
.TeamFormProductsPanel_product_redeploy_time .InputField {
  margin: 0;
  display: inline-block;
}

.TeamFormProductsPanel_product_seat-limit input {
  width: 51px;
  padding: 0 8px;
}

.TeamFormProductsPanel_product_seats-left {
  width: 100px;
}

.TeamFormProductsPanel_product_redeploy_time input {
  width: 60px;
  padding: 0 8px;
  margin-right: 3px;
}

.TeamFormProductsPanel_product_redeploy_time .InputField {
  width: auto;
}
