@import '../../../vars.css';


.Button.CornerNotificationPopup_show-button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  font-size: 26px;

  z-index: 500;
  position: absolute;
  width: 56px;
  height: 56px;
  right: 60px;
  bottom: var(--height-footer);

  background: white;

  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 56px;
  border: none;

  /* Chrome, Edge, and Safari */
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  /* Firefox */
  animation-name: fadeIn;
  animation-duration: 1s;
}

.Button.CornerNotificationPopup_show-button.unread {
  width: 64px;
  height: 64px;
  right: 56px;
  bottom: calc(var(--height-footer) - 4px);
  border-radius: 64px;
  border: 4px solid var(--color-blue-700);
}

.CornerNotificationPopup_show-button:hover {
  opacity: 80%;
}

.icon-corner-notification {
  position: relative;

  color: var(--color-green-500);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    filter: drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.15));
  }
  100% {
    opacity: 1;
  }
}

@keyframes ripple {
  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

.ripple {
  position: absolute;
  right: 60px;
  bottom: var(--height-footer);
  transform-origin: center center;
  transform: scale(0.9);
  animation: 1.5s ripple ease infinite;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid var(--color-blue-700);
  box-sizing: border-box;
}

/* Also make the ripple sibling element bigger when popup is unread */
.CornerNotificationPopup_show-button.unread + .ripple {
  width: 64px;
  height: 64px;
  right: 56px;
  bottom: calc(var(--height-footer) - 4px);
}
