@import '../../../vars.css';

.TokenLinkResultMessage {
  margin: 144px calc(-1 * var(--page-padding-side)) 600px;
  height: 322px;

  text-align: center;
  background-color: var(--color-neutral-50);
  border: solid var(--color-neutral-100);
  border-width: 1px 0;
}

.TokenLinkResultMessage_title {
  padding-top: 87px;

  color: var(--color-green-500);
  font-size: 26px;
  font-weight: bold;
}

.TokenLinkResultMessage_title.negative {
  color: var(--color-red-500);
}

.TokenLinkResultMessage_body {
  padding-top: 18px;
  padding-bottom: 21px;

  color: var(--color-neutral-800);
  font-size: 26px;
  font-weight: var(--font-weight-semi-bold);
}

.TokenLinkResultMessage_footer {
  font-size: 18px;
}
