@import '../../vars.css';

.TokenUrlLabel.for-panel {
  display: inline-block;
  width: 476px;
  position: relative;
  top: 2px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
