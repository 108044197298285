@import '../../../../../vars.css';

/* Chart header */

.ProductMetricChart_header {
  margin-right: 20px;
  padding-bottom: 15px;
}

.ProductMetricChart_header_title {
  font-size: 18px;
  font-weight: bold;
}

.ProductMetricChart_header_spinner_big {
  margin-left: 12px;

  animation-duration: 3s;
  color: var(--color-green-500);
  font-size: 20px;
}

.ProductMetricChart_header_spinner_small {
  margin-left: 3px;

  animation-direction: reverse;
  color: var(--color-green-500);
  font-size: 15px;
}

/* Chart */

.ProductMetricChart_label {
  font-size: 11px;
}

.ProductMetricChart_label.main-granularity {
  fill: var(--color-neutral-400);
  font-size: 16px;
  font-weight: bold;
}

.ProductMetricChart_tooltip-text {
  color: white;
  font-size: 12px;
}

.ProductMetricChart_tooltip-text_value {
  font-weight: bold;
}
