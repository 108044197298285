@import '../../../../../vars.css';

.ProjectedSavingsPanel {
  height: 600px;
}


.ProjectedSavingsPanel_configuration-icon {
  margin-right: 5px;
}

.ProjectedSavingsPanel_configuration-button.Button.link {
  color: var(--color-green-500);
}

.ProjectedSavingsPanel_configuration-button.Button.link:focus,
.ProjectedSavingsPanel_configuration-button.Button.link:hover {
  color: var(--color-green-500);
}

.ProjectedSavingsPanel_period-select {
  display: inline-block;
  margin-top: -6px;
  margin-left: 10px;
  width: 120px;
}

.ProjectedSavingsPanel_savings-in-period-text {
  margin-top: -2px;

  font-size: 18px;
  line-height: 1.6;
}

.ProjectedSavingsPanel .ProjectedSavingsPanel_savings-numbers_bottom-border {
  width: 465px;
}

.ProjectedSavingsPanel_usage_message {
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  height: 75px;
  padding: 10px;
  margin-bottom: 43px;

  font-size: 20px;
  font-weight: var(--font-weight-semi-bold);
}
