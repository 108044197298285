@import '../../vars.css';

:root {
  --tab-height: 59px;
}

.TeamTokenPage {
  margin-top: 13px;
}

.TeamTokenPage h1,
.TeamTokenPage h2,
.TeamTokenPage h3 {
  color: black;
}

.TeamTokenPage_content {
  margin-bottom: calc(-1 * var(--page-padding-bottom));
  margin-left: calc(-1 * var(--page-padding-side));
  margin-right: calc(-1 * var(--page-padding-side));
}

.TeamTokenPage_instructions-panel {
  padding: 0 var(--page-padding-side);
  margin-top: 12px;

  border-width: 1px 0;
}

.TeamTokenPage_instructions-panel_gradient {
  background-image: linear-gradient(to bottom, white, var(--color-neutral-50));
  margin-bottom: calc(-1 * var(--tab-height));
  margin-left: calc(-1 * var(--page-padding-side));
  margin-right: calc(-1 * var(--page-padding-side));
  height: var(--tab-height);
}

.TeamTokenPage_tab {
  border: 1px solid transparent;
  border-bottom: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  height: var(--tab-height);
  padding: 15px 40px 0;
  position: relative;
  top: 1px;
}

.TeamTokenPage_tab.selected {
  background-color: white;
  border-color: var(--color-neutral-100);
}

.TeamTokenPage_tab:not(.selected):hover {
  background-color: var(--color-neutral-50);
  border: 1px solid var(--color-neutral-100);
}

.TeamTokenPage_tab-text {
  color: var(--color-neutral-800);
}

.TeamTokenPage_tab-content {
  border-top: 1px solid var(--color-neutral-100);
  padding: 40px var(--page-padding-side);
  margin-left: calc(-1 * var(--page-padding-side));
  margin-right: calc(-1 * var(--page-padding-side));
}

.TeamTokenPage_tab-content_row:first-child {
  margin-bottom: 25px;
}

.TeamTokenPage_tab-content_cell {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.TeamTokenPage_tab-content_cell:first-child {
  padding-right: 36px;
}

.TeamTokenPage_tab-content_cell .Alert:last-child {
  margin-bottom: 0;
}

.TeamTokenPage_tab-content_cell .SelectField_label {
  font-weight: normal;
}

.TeamTokenPage_tab-content_component-title-row {
  margin-bottom: 5px;
}

.TeamTokenPage_tab-content_environment-select {
  width: 250px;
}

.TeamTokenPage_tab-content_component-title-row_title {
  display: inline-block;
}

.TeamTokenPage_tab-content_component-title-row_more-info {
  display: inline-block;
  float: right;
}

.TeamTokenPage_tab-content_component-title-row_more-info_url {
  color: var(--color-neutral-400);
  font-size: 12px;
}

.TeamTokenPage_tab-content_component-title-row_more-info_url:focus,
.TeamTokenPage_tab-content_component-title-row_more-info_url:hover {
  color: var(--color-neutral-800);
}

.TeamTokenPage_tab-content_team-url {
  background-color: var(--color-neutral-50);
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 7px 8px 15px;
}

.TeamTokenPage_tab-content_team-url_link {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 460px;
}

.TeamTokenPage_tab-content_team-url_button {
  float: right;
  font-size: 12px;
  height: 22px;
  padding: 0 12px;
}

.TeamTokenPage_tab-content_copyable-url {
  color: var(--color-green-500);
  cursor: pointer;
  font-weight: bold;
  font-size: inherit;
  vertical-align: baseline;
  padding: 0;
  text-decoration: underline;
}

.TeamTokenPage_tab-content_copyable-url:hover,
.TeamTokenPage_tab-content_copyable-url:focus {
  color: var(--color-green-500);
}

.TeamTokenPage_tab-content_instruction-list {
  font-size: 18px;
  margin-top: 20px;
}

.TeamTokenPage_tab-content_instruction-list > ol {
  padding-left: 20px;
  margin-bottom: 10px;
}

.TeamTokenPage_tab-content_instruction-list > ol > li {
  padding-left: 10px;
}

.TeamTokenPage_tab-content_instruction-list_inline-alert {
  display: inline-block;
  margin-top: 5px;
}

span.TeamTokenPage_monospace,
div.TeamTokenPage_monospace {
  background-color: var(--color-neutral-50);
  border-radius: 4px;
  color: var(--color-text);
  font-family: var(--font-family-code);
}

span.TeamTokenPage_monospace {
  padding: 2px 4px;
}

div.TeamTokenPage_monospace {
  font-size: 10px;
  padding: 9px;
}

.TeamTokenPage_tab-content_alert-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.TeamTokenPage_tab-content_instruction-list_inline-alert ul,
.TeamTokenPage_tab-content_good-to-know-alert ul,
.TeamTokenPage_tab-content_did-not-work-alert ul {
  list-style-type: disc;
  padding-left: 18px;
}

.TeamTokenPage_tab-content_instruction-list li,
.TeamTokenPage_tab-content_good-to-know-alert li,
.TeamTokenPage_tab-content_did-not-work-alert li {
  margin-bottom: 5px;
}

.TeamTokenPage_tab-content_did-not-work-alert a,
.TeamTokenPage_tab-content_instruction-list a {
  font-weight: bold;
}

.TokenPage_footer {
  background-color: var(--color-neutral-50);
  font-size: 18px;
  height: 83px;
  padding: 30px var(--page-padding-side);
}
