.CopyTextButton_tooltip {
  position: absolute;
  z-index: 1
}

.CopyTextButton_tooltip_icon {
  font-size: 18px;
  margin-right: 5px;
  vertical-align: bottom;
}
