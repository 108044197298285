@import '../../../vars.css';

.Dashboard_fetching {
  margin-top: 12px;
  text-align: center;
}

.Dashboard_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}

.Dashboard_top_details {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.Dashboard_top_company {
  display: flex;
  gap: 16px;
}

.Dashboard_top_license {
  align-items: center;
  border-radius: 4px;
  display: flex;
  padding: 2px 6px;
}

.Dashboard_top_license-active {
  background-color: var(--color-green-100);
  color: var(--color-green-800);
}

.Dashboard_top_license-expired {
  background-color: var(--color-red-50);
  color: var(--color-red-800);
}

.Dashboard_top_details h4 {
  font-weight: normal;
}

.Dashboard_top_buy-link {
  align-items: center;
  display: flex;
  line-height: 20px;
}

.Dashboard_details {
  color: black;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Dashboard_cards-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.Dashboard_cards {
  align-items: flex-start;
  display: flex;
  flex: 1;
  gap: 24px;
}

.Dashboard_card {
  border: 1px solid var(--color-neutral-200);
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px 32px;
}

.Dashboard_card1 {
  gap: 16px;
}

.Dashboard_card2 {
  gap: 24px;
}

.Dashboard_card_header {
  align-items: flex-start;
  display: flex;
}

.Dashboard_card_header h5 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.Dashboard_card_content {
  align-items: center;
  display: flex;
  flex: 1;
}

.Dashboard_card1 .Dashboard_card_content {
  color: var(--color-neutral-500);
  font-size: 18px;
  justify-content: space-between;
}

.Dashboard_card2 .Dashboard_card_content {
  color: var(--color-green-500);
  flex-direction: row;
  font-size: 30px;
  padding-left: 48px;
  padding-right: 48px;
  justify-content: space-between;
}

i.vertical-separator {
  height: 66px;
  width: 1px;
  border: 1px solid var(--color-neutral-200);
}

.Dashboard_card_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Dashboard_card_stat {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Dashboard_card1 .Dashboard_card_wrapper .Dashboard_card_stat {
  align-items: flex-start;
  gap: 0;
}

.Dashboard_card_stat_name {
  line-height: 28px;
}

.Dashboard_card2 .Dashboard_card_stat_name {
  align-items: center;
  align-self: stretch;
  color: var(--color-neutral-500);
  display: flex;
  flex-direction: row;
  font-size: 18px;
  gap: 6px;
  justify-content: center;
}

.Dashboard_card_stat_name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
}

.Dashboard_card1 .Dashboard_card_stat_value {
  color: var(--color-green-500);
  font-size: 26px;
  line-height: 30px;
}

.Dashboard_card2 .Dashboard_card_stat_value {
  line-height: 36px;
}

.Dashboard_teams {
  align-self: stretch;
  border: 1px solid var(--color-neutral-200);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 32px;
}

.Dashboard_teams_top {
  display: flex;
  justify-content: space-between;
}

.Dashboard_teams_top_left {
  align-items: baseline;
  display: flex;
  gap: 16px;
}

.Dashboard_teams_top_filter {
  width: 175px;
}

.Dashboard_teams_top_buttons {
  display: flex;
  gap: 8px;
}

@media (max-width: 1596px) {
  .Dashboard_cards-wrapper {
    flex-direction: column;
  }

  .Dashboard_cards {
    align-self: stretch;
  }
}
