@font-face {
  font-family: 'clsicons';
  src: url('clsicons.eot?22618551');
  src: url('clsicons.eot?22618551#iefix') format('embedded-opentype'),
       url('clsicons.woff2?22618551') format('woff2'),
       url('clsicons.woff?22618551') format('woff'),
       url('clsicons.ttf?22618551') format('truetype'),
       url('clsicons.svg?22618551#clsicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  display: inline-block;
  font: normal normal normal 14px/1 clsicons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: '\e800';
}

.icon-clock-dotted:before {
  content: '\e801';
}

.icon-clock-full:before {
  content: '\e802';
}

.icon-close:before {
  content: '\e803';
}

.icon-info:before {
  content: '\e804';
}

.icon-onboarding-arrow-1:before {
  content: '\e805';
}

.icon-onboarding-arrow-2:before {
  content: '\e806';
}

.icon-onboarding-arrow-3:before {
  content: '\e807';
}

.icon-paper-plane:before {
  content: '\e808';
}

.icon-people:before {
  content: '\e809';
}

.icon-person-head:before {
  content: '\e80a';
}

.icon-piggy-bank:before {
  content: '\e80b';
}

.icon-piggy-bank-o:before {
  content: '\e80c';
}

.icon-rotating-arrows:before {
  content: '\e80d';
}

.icon-corner-notification:before {
  content: '\e80e';
}

.icon-chevron:before {
  content: '\e80f';
}

.icon-chevron-down:before {
  content: '\e80f';
}

.icon-chevron-up {
  transform: rotate(180deg);
}

.icon-chevron-up:before {
  content: '\e80f';
}

.icon-chevron-left {
  transform: rotate(90deg);
}

.icon-chevron-left:before {
  content: '\e80f';
}

.icon-chevron-right {
  transform: rotate(270deg);
}

.icon-chevron-right:before {
  content: '\e80f';
}

.icon-notification:before {
  content: '\e810';
}

.icon-help:before {
  content: '\e811';
}

.icon-actions:before {
  content: '\e812';
}

.icon-favourite:before {
  content: '\e813';
}

.icon-search:before {
  content: '\e814';
}
