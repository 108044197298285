.CardHeading > .CardTitle {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.1;
}

.CardHeading {
  color: var(--color-neutral-900);
  background-color: var(--color-neutral-50);
  padding: 10px 15px;
  border-bottom: 1px solid var(--color-neutral-100);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.Card {
  border: 1px solid var(--color-neutral-100);
  border-radius: 6px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  margin-bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.CardBody {
  padding: 15px;
  overflow: auto;
}
