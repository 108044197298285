.TeamFormSuccessModal_text {
  margin-bottom: 23px;

  font-size: 18px;
  line-height: 1.5;
}

.TeamFormSuccessModal_ok-button {
  padding-left: 45px;
  padding-right: 45px;
}

.TeamFormSuccessModal_team-details {
  width: 75%;
  overflow: hidden;
}

.TeamFormSuccessModal_team-action {
  width: 25%;
}

.Modal_footer:has(.TeamForm_link) {
  flex-direction: row;
  justify-content: space-between;
}

.TeamPanel .CardHeading {
  width: 100%;
}

.TeamPanel .CardBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
