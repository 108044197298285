/*Email domain whitelist*/

th.EmailDomainWhitelist_domain-column {
  width: 21%;
}

th.EmailDomainWhitelist_created-column {
  width: 71%;
}

th.EmailDomainWhitelist_actions-column {
  width: 8%;
}

.EmailDomainWhitelist_fetching {
  padding-top: 25px;
}

.EmailDomainWhitelist_no-results {
  align-items: center;
}

.TableRow.EmailDomainWhitelist_no-results > .TableCell {
  padding: 16px 0 !important;
}

.EmailDomainWhitelist_no-results-message {
  display: flex;
  padding: 32px 0;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  text-align: start;
  gap: 56px;
}

.EmailDomainWhitelist_no-results-text {
  display: inherit;
  flex-direction: column;
  gap: 8px;
}

.EmailDomainWhitelist_no-results-message h2 {
  color: var(--color-green-500);
  font-weight: bold;
}


.EmailDomainWhitelist_no-results-image {
  background-image: url('no-domains.svg');
  background-repeat: no-repeat;
  height: 240px;
  width: 240px;
}

.EmailDomainWhitelist_domain {
  font-weight: bold;
}

.EmailDomainWhitelist_created-by {
  margin-top: 5px;

  font-size: 10px;
  color: var(--color-neutral-400);
}

.EmailDomainWhitelist_delete-button {
  height: 20px;
  padding: 0 10px 2px;

  font-size: 12px;
}

.DeleteWhitelistedDomainModal_product-installation-list.pre-scrollable {
  max-height: 140px;
}
.DeleteLastWhitelistedDomainModal_confirmation-text,
.DeleteWhitelistedDomainModal_confirmation-text {
  margin-top: 20px;
}
