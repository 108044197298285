.Dropdown_menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px;

  background: white;
  box-shadow: 2px 8px 14px 0 rgba(0, 0, 0, 0.08), 0 4px 16px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.DropdownMenu_item {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  align-self: stretch;

  text-decoration: none;
  border: none;
  border-radius: 0;
  background-color: white;
}

.DropdownMenu_item {
  color: var(--color-text);
}

.topSeparator {
  border-top: 1px solid var(--color-neutral-100, #E0E0E0);
}

.DropdownMenu_item:hover:not(.menuItemWithSubtext) {
  color: var(--color-text);
  text-decoration: none;
  background-color: var(--color-neutral-50);
}

.DropdownMenu_item.disabled {
  color: var(--color-neutral-400);
  background-color: white;
  cursor: default;
}
