@import '../vars.css';

.App_footer-filler {
  height: var(--height-footer);
}

.App_container.Container {
  box-sizing: content-box;

  padding: 92px var(--page-padding-side) var(--page-padding-bottom);
  /*width: calc(var(--width-content) - var(--page-padding-side) * 2);*/
  margin: 0 var(--page-margin-side) 0;

  background: white;
}
