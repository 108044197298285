@import '../../vars.css';

:root {
  --icon-width: 26px;
}

.Alert {
  padding: 11px 14px 14px;
  margin-bottom: 20px;

  font-size: 14px;
  border-radius: 4px;
}

.Alert_content {
  margin-left: var(--icon-width);
  position: relative;
  top: 2px;
}

.Alert_icon.fa {
  float: left;
  position: relative;
  top: 4px;
  width: var(--icon-width);

  font-size: 16px;
}

.Alert_button {
  height: 20px;
  padding: 0 10px 2px;
  margin-left: 20px;

  font-size: 12px;
}

.AlertEmphasis {
  font-weight: bold;
}

.Alert.error,
.Alert.critical {
  background-color: var(--color-red-50);
}

.Alert.error a,
.Alert.error .Alert_icon,
.Alert.critical a,
.Alert.critical .Alert_icon {
  color: var(--color-red-600);
}

.Alert.warn {
  background-color: var(--color-orange-50);
}

.Alert.warn a,
.Alert.warn .Alert_icon {
  color: var(--color-orange-600);
}

.Alert.info {
  background-color: var(--color-blue-100);
}

.Alert.info a,
.Alert.info .Alert_icon {
  color: var(--color-blue-600);
}

.Alert.ok {
  background-color: var(--color-green-100);
}

.Alert.ok a,
.Alert.ok .Alert_icon {
  color: var(--color-green-500);
}

.Alert.instruction {
  background-color: var(--color-neutral-50);
}
