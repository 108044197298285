@import '../../../../vars.css';

.DashboardOnboarding_overlay {
  border-radius: 5px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, var(--opacity-overlay));
  color: #fff;
  font-size: 25px;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  z-index: 1;
}

.DashboardOnboarding_close-button {
  background: none;
  border: none;
  color: var(--color-neutral-50);
  font-size: 20px;
  font-weight: bold;
  outline: none;
  padding: 90px 54px;
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.DashboardOnboarding_close-button > .icon-close {
  font-size: 17px;
  padding-left: 14px;
}

.DashboardOnboarding_container {
  position: relative;
}

.DashboardOnboarding_content {
  bottom: 116px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.DashboardOnboarding_content_action {
  color: var(--color-green-500);
  font-weight: bold;
}

.DashboardOnboarding_content_action > * {
  display: inline-block;
}

.DashboardOnboarding_content_action_or {
  font-weight: normal;
  margin: 0 60px;
}

.DashboardOnboarding_content .icon-onboarding-arrow-2 {
  color: var(--color-green-500);
  font-size: 300px;
  position: absolute;
  rotate: 290deg;
  top: 250px;
  right: 260px;
}

.DashboardOnboarding_content .icon-onboarding-arrow-3 {
  color: var(--color-green-500);
  font-size: 200px;
  position: absolute;
  right: 130px;
  top: 100px;
}
