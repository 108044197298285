@import '../../../../../vars.css';

.RoiCalculatorPanel .CardBody,
.RoiCalculatorPanel .CardFooter {
  padding-top: 26px;
  padding-left: 30px;
  padding-right: 30px;
}

.RoiCalculatorPanel_header {
  padding-bottom: 14px;
}

.RoiCalculatorPanel_header-left-part {
  display: inline-block;
  width: 70%;
}

.RoiCalculatorPanel_header-left-part_title {
  display: inline-block;
  padding-top: 5px;
}

.RoiCalculatorPanel_header-right-part {
  display: inline-block;
  width: 100px;

  text-align: right;
}

.RoiCalculatorPanel_header-right-part_button.Button.link,
.RoiCalculatorPanel_header-right-part_button.Button.link:focus,
.RoiCalculatorPanel_header-right-part_button.Button.link:hover {
  font-size: 12px;
  color: inherit;
}

.RoiCalculatorPanel_central-section {
  padding-top: 13px;
}

.RoiCalculatorPanel_savings-numbers h5 {
  margin-top: 28px;
  margin-bottom: 18px;

  color: var(--color-green-500);
}

.RoiCalculatorPanel_savings-numbers {
  font-size: 14px;
}

.RoiCalculatorPanel_savings-numbers_row {
  margin-bottom: 8px;
}

.RoiCalculatorPanel_savings-numbers_row:last-child {
  margin-bottom: 0;
}

.RoiCalculatorPanel_savings-numbers_row > * {
  display: inline-block;
}

.RoiCalculatorPanel_savings-numbers_number {
  font-weight: bold;
}

.RoiCalculatorPanel_footer.CardFooter {
  padding: 17px 30px 30px;
  height: 90px;
  background: transparent;

  border-top: none;
}

.RoiCalculatorPanel_footer > * {
  display: inline-block;
}

.RoiSummary {
  width: 100%;
  display: flex;
  align-items: center;
}

.RoiSummary_jrebel-logo {
  height: 33px;
  /* defining width for IE10, which otherwise pads the centered logo with whitespace, keeping the original width of svg */
  width: 80px;
}

.RoiSummary_roi-text {
  margin-top: -3px;
  padding-left: 18px;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-neutral-800);
}

.RoiSummary_roi-number {
  font-size: 26px;
}
