@import '../../../../vars.css';

.AdminUserSettingsTab, /*TODO Cleanup styles*/
.OrganizationSettingsTab {
  display: flex;
  /*padding: 32px 36px 50px 36px;*/
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
}

.EmailDomainWhitelistSettings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.AdminUserResetPassword,
.EmailDomainWhitelistSettings_add_domain,
.TwoStepAuthentication,
.DefaultTeamsView {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.AdminUserResetPassword_details,
.EmailDomainWhitelistSettings_details,
.TwoStepAuthentication_details,
.DefaultTeamsView_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
