@import 'vars.css';
@import 'fonts/styles.css';

:root {
  --color-text: var(--color-neutral-900, #333333);
  --color-placeholer: var(--color-neutral-600);
}

/* base */

html {
  height: 100%;
}

*, :after, :before {
  box-sizing: border-box;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;

  font-family: var(--font-family-base);
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text);
  fill: var(--color-text);
  background: var(--color-neutral-50);
}

h1 {
  font-size: 30px;
  line-height: 36px;
}

h2 {
  font-size: 26px;
  line-height: 30px;
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

h4 {
  font-size: 18px;
  line-height: 28px;
}

h5 {
  font-size: 16px;
  line-height: 24px;
}

h6 {
  font-size: 12px;
  line-height: 20px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  color: var(--color-green-500);
  text-decoration: underline;
}

a:hover, a:focus {
  color: var(--color-green-600);
}

p {
  margin: 0 0 10px;
}

input {
  border: 1px solid var(--color-neutral-400);
  border-radius: 2px;
  box-sizing: border-box;
  height: 14px;
  width: 14px;
}


.text-code {
  font-family: var(--font-family-code);
  font-size: 11px;
  line-height: 14px;
}

/* Scrollbar styling */

/* Firefox */
.scroll-grey * {
  scrollbar-width: thin;
  scrollbar-color: var(--color-neutral-300) var(--color-neutral-50);
}

/* Chrome, Edge, and Safari */
.scroll-grey *::-webkit-scrollbar {
  width: 8px;
}

.scroll-grey *::-webkit-scrollbar-track {
  background: var(--color-neutral-50, #F8F8F8);
  border-radius: 100px;
}

.scroll-grey *::-webkit-scrollbar-thumb {
  background-color: var(--color-neutral-300, #B3B3B3);
  border-radius: 100px;
  border: 1px solid var(--color-neutral-50, #F8F8F8);
}
