.UsageGauge_speedometer {
  position: relative;
  width: 250px;
  height: 200px;
  margin: 6px auto 69px 88px;
}

.UsageGauge_pie {
  position: absolute;
}

.UsageGauge_pie.UsageGauge_pie_dial {
  top: 5px;
  left: 5px;
  width: 238px;
  height: 250px;
}

.UsageGauge_pie.UsageGauge_pie_pointer {
  top: 3px;
  left: 3px;
  width: 242px;
  height: 252px;
}

.UsageGauge_pie.UsageGauge_pie_inner_semicircle {
  top: 30px;
  left: 24px;
  width: 200px;
  height: 100px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border: 1px dashed var(--color-blue-200);
  border-bottom: 0;
}

.UsageGauge_bar {
  position: absolute;
  top: 150px;
  left: 5px;
  width: 240px;
  height: 4px;
}

.UsageGauge_ratio {
  text-align: center;
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
}

.UsageGauge_ratio_numbers {
  font-size: 28px;
  font-weight: var(--font-weight-semi-bold);
}

.UsageGauge_ratio_text {

}

.UsageGauge_bar_numbers {
  position:relative;
  top: 17px;
}

.UsageGauge_bar_numbers_number {
  position: absolute;
  transform: translateX(-50%);
}

.UsageGauge_pie.UsageGauge_pie_pointer svg {
  /* This fixes the clipping of the edge of the dial pointer, which has a thick border. */
  overflow: visible;
}
