@import '../../../vars.css';

:root {
  @media (min-width: 1441px) {
    --team-table-name-cell: 320px;
    --team-table-name-label: 287px;
    --team-table-lead-cell: 191px;
  }

  @media (max-width: 1440px) and (min-width: 1134px) {
    --team-table-name-cell: 240px;
    --team-table-name-label: 207px;
    --team-table-lead-cell: 143px;
  }

  @media (max-width: 1133px) {
    --team-table-name-cell: 133px;
    --team-table-name-label: 100px;
    --team-table-lead-cell: 79px;
  }
}

.TeamTable.Table > thead > .TableRow > .TableCell:last-child,
.TeamTable.Table > tbody > .TableRow > .TableCell:last-child {
  padding-left: 0;
}

.TeamTable .TableRow:hover {
  background-color: var(--color-neutral-50);
}

.TableRow.TeamTable_no-results {
  align-items: center;
  text-align: center;
}

.TableRow.TeamTable_no-results > .TableCell {
  padding: 16px 0 !important;
}

.TeamTable_no-results_message {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 56px;
  justify-content: center;
  padding: 32px 0;
  text-align: start;
}

.TeamTable_no-results_text {
  display: inherit;
  flex-direction: column;
  gap: 8px;
}

.TeamTable_no-results_message h2 {
  color: var(--color-green-500);
  font-weight: bold;
}

.TeamTable_no-results_image {
  background-image: url('no-teams.svg');
  background-repeat: no-repeat;
  height: 240px;
  width: 240px;
}

.TableRow.TeamTable_no-results:hover {
  background-color: transparent;
}

.TeamTable_team-name-label,
.TeamTable_team-name-button {
  background: transparent;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: var(--team-table-name-label);
}

.TeamTable_team-name-button {
  cursor: pointer;
}

.TeamTable .TableRow:hover .TeamTable_team-name-button {
  color: var(--color-green-500);
}

.TeamTable_team-url-button {
  background: transparent;
  border: 0;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: var(--team-table-name-label);
}

.TeamTable_team-lead-label {
  color: var(--color-neutral-400);
  font-size: 12px;
  overflow: hidden;
}

th.TeamTable_name-column {
  min-width: var(--team-table-name-cell);
}

th.TeamTable_lead-column {
  min-width: var(--team-table-lead-cell);
}

th.TeamTable_product-column {
  min-width: 100px;
}

th.TeamTable_usage-column {
  min-width: 100px;
}

th.TeamTable_created-column {
  min-width: 100px;
}

.TeamTable_created-label {
  color: var(--color-neutral-400);
  font-size: 11px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th.TeamTable_actions-column {
  width: 60px;
}

.TeamTable .TableCell.favourite {
  position: relative;
}

.TeamTable .TableCell .icon-favourite {
  color: var(--color-neutral-400);
  display: none;
  font-size: 12px;
  height: 12px;
  left: 4px;
  position: absolute;
  top: 7px;
  width: 12px;
}

.TeamTable .TableCell.favourite .icon-favourite {
  display: flex;
}

th.TeamTable_checkbox-column {
  width: 48px;
}
