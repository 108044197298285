@import '../../../vars.css';

.AdminUserSettingsTab,
.OrganizationSettingsTab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
}

.EmailDomainWhitelistSettings,
.SsoSettings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 24px;
}

.AdminUserResetPassword,
.EmailDomainWhitelistSettings_add_domain,
.SsoSettingsPanel_add_sso,
.TwoFactorAuthentication,
.DefaultTeamsView {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.AdminUserResetPassword_details,
.EmailDomainWhitelistSettings_details,
.SsoSettingsPanel_description,
.TwoFactorAuthentication_details,
.DefaultTeamsView_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
