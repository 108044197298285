@import '../../vars.css';

.InputField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  margin-bottom: 16px;
  width: 100%;
}

.InputField_input {
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--color-neutral-200, #CACACA);
  width: 100%;
  height: 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  font-family: inherit;
}

.InputField_input::placeholder {
  color: var(--color-placeholer);
  opacity: 0.5;
}

.InputField_input:-ms-input-placeholder {
  color: var(--color-placeholer);
}

.InputField_input::-ms-input-placeholder {
  color: var(--color-placeholer);
}

.InputField_input:disabled {
  cursor: not-allowed;
  background-color: var(--color-neutral-100);
  opacity: 1;
}

.InputField_input:focus {
  border: 1px solid var(--color-green-500);
  border-color: var(--color-green-500);
  outline: none;
}

.InputField.has-feedback input.InputField_input {
  padding-right: 43px;
}

.InputField.has-error input.InputField_input,
.InputField.has-error input.InputField_input:focus {
  border-color: var(--color-red-500);
}

.InputField_label {
  display: inline-block;
  max-width: 100%;
  font-weight: bold;
}

.InputField.has-error .InputField_error {
  color: var(--color-red-500);
  border-color: var(--color-red-500);
  font-size: 12px;
  font-weight: var(--font-weight-semi-bold);
}

.InputField.has-error .InputField_error-icon {
  color: var(--color-red-500);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.InputField_input-wrapper {
  position: relative;
  width: 100%;
}
