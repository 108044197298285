.SsoRedirectPage {
    text-align: center;
}

.SsoRedirectPage > span {
    margin: 1em;
    display: inline-block;
}
.SsoRedirectPage h3 {
    margin-top: 2em;
}

.SsoRedirectPage .error-message {
    display: inline-block;
    margin-top: 0.5em;
}

.SsoRedirectPage .error-details, .SsoRedirectPage .error-stacktrace {
    display: inline-block;
    margin-top: 1em;
    font-weight: bold;
}

.SsoRedirectPage div.technical {
    margin: 1em;
    display: inline-block;
    text-align: left;
    width: fit-content;
}

.SsoRedirectPage pre {
    margin: 0;
}
