@import '../../../../vars.css';

.TableRow.AdminUserSettings_fetching { /*TODO Cleanup styles*/
  text-align: center;
}

.Table > thead > .TableRow > th.AdminUserSettings_toggle_all {
  text-align: right;
  padding-right: 42px;
}

.TableCell:has(> .Toggle) {
  padding-right: 42px !important;
}

.AdminUserSettingsTab,
.OrganizationSettingsTab {
  display: flex;
  /*padding: 32px 36px 50px 36px;*/
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
}
.EmailDomainWhitelistSettings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.AdminUserResetPassword,
.EmailDomainWhitelistSettings_add_domain,
.TwoFactorAuthentication,
.DefaultTeamsView {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.AdminUserResetPassword_details,
.EmailDomainWhitelistSettings_details,
.TwoFactorAuthentication_details,
.DefaultTeamsView_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.AdminUserEmailNotificationSettings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: 100%;
}
.AdminUserEmailNotificationSettings_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
