@import '../../vars.css';

.TabBar {
  display: flex;
  height: 32px;
  padding: 0;
  margin: 0 0 35px;

  border-bottom: 1px solid var(--color-neutral-200);
  gap: 32px;
}

.TabBar li {
  height: 32px;
  list-style: none;
  float: left;
}

.TabBar a {
  display: flex;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;

  color: var(--color-neutral-900);

  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
  text-decoration: none;
}

.TabBar a:hover,
.TabBar a.active {
  color: var(--color-green-500);
}

.TabBar a.active {
  border-color: var(--color-green-500);
  font-weight: bold;
}
