@import '../../vars.css';

.Bubble {
  display: inline-block;
  padding: 3px 12px;
  margin-right: 10px;
  margin-bottom: 10px;

  border-radius: 12px;
  background-color: var(--color-neutral-100);
}

.Bubble:last-child {
  margin-right: 0;
}

.Bubble_warning-icon {
  margin-right: 7px;
}

.Bubble:hover {
  background-color: var(--color-neutral-300);
}

.Bubble_remove-button {
  position: relative;
  bottom: 1px;
  padding: 0;
  margin-left: 7px;

  outline: none;
  background: none;
  border: none;

  font-size: 8px;
  color: var(--color-neutral-400);
}

.Bubble:hover .Bubble_remove-button {
  color: var(--color-text);
}
