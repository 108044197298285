@import '../../vars.css';

.TeamPanel .Card {
  box-shadow: none;
}

.TeamPanel .Card > .CardHeading {

  background: none;
}

.TeamPanel h4 {
  font-weight: bold;
  color: var(--color-green-500);
}

.TeamPanel .CardHeading.can-be-pressed:hover {
  cursor: pointer;
}

.TeamPanel .CardHeading.can-be-pressed:hover h4 {
  color: var(--color-green-500);
}

.TeamPanel .CardBody:hover,
.TeamPanel .CardBody.with-hover-style {
  background-color: var(--color-neutral-50);
}
