@import '../../vars.css';

.ActionsButton {
  display: inline-flex;
  padding: 8px !important;
  align-items: flex-start !important;
  border-radius: 4px;
  background: white;
  border: none !important;
}

.ActionsButton:hover {
  background: var(--color-neutral-50, #F8F8F8);
}

.ActionsButton.pressed {
  box-shadow: 0 1px 6px 4px rgba(0, 0, 0, 0.18) inset !important;
}

.icon-actions {
  font-size: 16px !important;
}
