@import '../../vars.css';

:root {
  --box-shadow: rgba(0, 0, 0, 0.1);
  --toggle-width: 62px;
  --thumb-width: 26px;
  --padding-size: 3px;
}

.Toggle {
  background-color: var(--color-neutral-100);
  border-radius: 3px;
  box-shadow: inset 0 1px var(--box-shadow);
  box-sizing: border-box;
  cursor: pointer;
  padding: var(--padding-size);
  width: var(--toggle-width);

  transition-duration: 0.2s;
  transition-property: background-color;
  transition-timing-function: ease-out;
}

.Toggle.enabled {
  background-color: var(--color-green-500);
}

.Toggle.readonly {
  cursor: not-allowed;
  opacity: 0.4;
}

.Toggle_thumb {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 1px var(--box-shadow);
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  padding: 2px 6px;
  text-align: center;
  width: var(--thumb-width);

  transition-duration: 0.2s;
  transition-property: margin;
  transition-timing-function: ease-out;
}

.Toggle_thumb::selection {
  background: transparent;
}

.Toggle.enabled .Toggle_thumb {
  margin: 0 0 0 calc(var(--toggle-width) - var(--thumb-width) - 2 * var(--padding-size));
}
