@import '../../../vars.css';

.SeatUserInviteForm_light-title {
  font-weight: normal;
}

.SeatUserInviteForm_email-input {
  display: flex;
  gap: 10px;
  align-self: stretch;
}

.SeatUserInviteForm_email-field {
  display: flex;
  flex: 1 0 0;
}

.SeatUserInviteForm_email-add-button {
  display: flex;
  align-items: center;
  height: var(--modal-height-input);
  margin-top: 24px;
}

.SeatUserInviteForm_email-bubbles {
  margin-bottom: -10px;
}
