@import '../../../../vars.css';

.ExportDedicatedForm_light-title {
  font-weight: normal;
}

.ExportDedicatedForm_available-seats {
  font-size: 18px;
}

.ExportDedicatedForm_available-seats_number {
  font-weight: bold;
}

.ExportDedicatedForm_fields {
  margin-top: 25px;
}

.ExportDedicatedForm_bubbles {
  margin-top: 20px;
  margin-bottom: -10px;
}
