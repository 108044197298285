@import '../../../vars.css';

.FeedbackFormModal_modal_body {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
}

.FeedbackFormModal_nps {
  display: inherit;
  flex-direction: inherit;
  gap: 16px;
}

.FeedbackFormModal_nps-score {
  display: inherit;
  flex-direction: inherit;
}

.FeedbackFormModal_score-buttons-wrapper {
  display: inherit;
  gap: 4px;
}

.Button.FeedbackFormModal_score-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 33px;
  height: 32px;
  background: inherit;
  border: 1px solid var(--color-neutral-200);
  border-radius: 3px;
}

.FeedbackFormModal_score-button:not(.selected):hover {
  border-color: var(--color-green-500);
  color: var(--color-green-500);
}

.Button.FeedbackFormModal_score-button:not(.selected):active {
  border: none;
  box-shadow: inset 0 1px 6px 4px rgba(0, 0, 0, 0.18);
  border-radius: 3px;
  color: var(--color-green-600);
}

.Button.FeedbackFormModal_score-button.selected {
  background: var(--color-green-500);
  border: none;
}

.FeedbackFormModal_score-label {
  display: inherit;
  justify-content: space-between;
  font-size: 12px;
}


.FeedbackFormModal_answer {
  display: inherit;
  flex-direction: inherit;
  align-self: inherit;
  gap: 8px;
}

.FeedbackFormModal_answer label,
.FeedbackFormModal_nps h4 {
  font-weight: bold;
}

.FeedbackFormModal_answer textarea {
  resize: vertical;
  height: 140px;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;

  border: 1px solid var(--color-neutral-400);
  border-radius: 4px;
  padding: 8px 0 8px 8px;
}

.FeedbackFormModal_answer textarea:hover {
  border-color: var(--color-neutral-800);
}

.FeedbackFormModal_answer textarea:active,
.FeedbackFormModal_answer textarea:focus {
  border-color: var(--color-green-500);
  outline: none !important;
}

.FeedbackFormModal_never-ask-again {
  display: inherit;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
