@import '../../../../vars.css';

.ExportManagedForm_light-title {
  font-weight: normal;
}

.ExportManagedForm_available-seats {
  font-size: 18px;
}

.ExportManagedForm_available-seats_number {
  font-weight: bold;
}

.ExportManagedForm_fields {
  margin-top: 25px;
}
