@import '../../vars.css';
@import '../../fonts/styles.css';

.Button {
  cursor: pointer;
  vertical-align: middle;
  padding: 6px 24px;
  border: 1px solid var(--color-neutral-400);
  border-radius: 4px;
  text-align: center;
  gap: 10px;
  line-height: 20px;
}

.Button[disabled] {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.4;
}

.Button.default {
  background-color: white;
}

.Button.primary {
  background-color: var(--color-green-500);
  border-color: var(--color-green-700);
  color: white;
}

.Button.warning {
  background-color: var(--color-orange-600);
  border-color: var(--color-orange-800);
  color: white;
}

.Button.danger {
  color: white;
  background-color: var(--color-red-500);
  border-color: var(--color-red-700);
}

.Button.primary:not([disabled]):hover,
.Button.danger:not([disabled]):hover,
.Button.warning:not([disabled]):hover,
.Button.default:not([disabled]):hover {
  box-shadow: 0 -32px 4px 0 rgba(0, 0, 0, 0.12) inset;
}

.Button.primary:not([disabled]):active,
.Button.danger:not([disabled]):active,
.Button.warning:not([disabled]):active,
.Button.default:not([disabled]):active {
  box-shadow: 0 6px 6px 4px rgba(0, 0, 0, 0.16) inset;
}

.Button.secondary, .Button.secondary:focus, .Button.secondary:active {
  color: var(--color-green-500);
  border-color: var(--color-green-500);
  background-color: transparent;
}

.Button.secondary:hover {
  color: var(--color-green-700);
  border-color: var(--color-green-700);
}

.Button.secondary.warning, .Button.secondary.warning:focus, .Button.secondary.warning:active {
  color: var(--color-orange-600);
  border-color: var(--color-orange-600);
  background-color: transparent;
}

.Button.secondary.warning:hover {
  color: var(--color-orange-800);
  border-color: var(--color-orange-800);
}

.Button.secondary.warning:not([disabled]):active,
.Button.secondary.warning:not([disabled]):hover {
  box-shadow: none;
}

.Button.secondary.danger, .Button.secondary.danger:focus, .Button.secondary.danger:active {
  color: var(--color-red-500);
  border-color: var(--color-red-500);
  background-color: transparent;
}

.Button.secondary.danger:hover {
  color: var(--color-red-700);
  border-color: var(--color-red-700);
}

.Button.secondary.danger:not([disabled]):active,
.Button.secondary.danger:not([disabled]):hover {
  box-shadow: none;
}

.Button.link {
  text-decoration: underline;
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.Button.default.active, .Button.default:active, .Button.default:hover {
  color: var(--color-neutral-900);
  background-color: var(--color-neutral-100);
  border-color: var(--color-neutral-300);
}
