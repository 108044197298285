@import '../../vars.css';

.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: var(--color-neutral-50);
}

.Footer .Container {
  padding: 0 var(--page-padding-side);
}

.Footer_text-box {
  display: flex;
  padding: 24px 8px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.Footer_text-box h6 {
  color: var(--color-neutral-500);
  font-weight: bold;
}

.Footer_text-box a,
.Footer_text-box a:hover,
.Footer_text-box a:focus {
  font-weight: bold;
  text-decoration: none;
}
